<template>
  <div class="application-wrap">
    <div class="bread-wrap">
      <span class="bread-title">云手机应用管理</span>
    </div>
    <div class="content">
      <div class="list-top">
        <el-button type="primary" class="createphone" @click="navtocreate"
          >上传应用</el-button
        >
      </div>
      <com-table
        class="applist"
        :headercellstyle="headercellstyle"
        :columns="appList"
        :data="tabledata"
        tableheight="calc(100vh - 300px)"
        :pagination="pagination"
        @handleSelectionChange="handleSelectionChange"
      >
        <el-table-column
          align="center"
          header-align="center"
          slot="action"
          label="操作"
          min-width="260"
        >
          <template slot-scope="scope">
            <el-button
              class="modifybtn"
              type="primary"
              size="mini"
              >安装</el-button
            >
          </template>
        </el-table-column>
      </com-table>
    </div>
  </div>
</template>
<script>
import ComTable from "@/components/Table";
import { appList } from "@/utils/tableList";
export default {
  data() {
    return {
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      appList: [],
      tabledata: [],
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
    };
  },
  components: { ComTable },
  beforeMount() {
    this.appList = appList;
  },
  methods: {
    handleSelectionChange() {},
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>